import React from "react";
import AboutHeader from "../assets/page-header/about-header.png";

const About = () => {
  return (
    <section className="h-auto pt-2 ">
      <div className="max-w-xl sm:max-w-4xl lg:max-w-7xl relative px-5 pt-20 pb-12 items-center mx-auto lg:mx-20 xl:mx-28 2xl:mx-40 3xl:mx-auto lg:pb-2 lg:px-1 xl:px-3 2xl:px-1">
        <h2 className="product capitalize text-white font-bold text-center relative z-10 lg:text-left text-3xl sm:text-4xl sm:leading-none pb-3 px-8">
          About Us
        </h2>
        <div className="absolute top-0 left-0 bg-dark-grayish-blue w-full h-48 rounded-md overflow-hidden">
          <img
            src={AboutHeader}
            alt="rows of sneakers"
            className="opacity-10 absolute h-full w-full object-cover"
          />
        </div>
      </div>
      <div className="mx-auto mb-6 w-3/4 mt-16 flex flex-col gap-6">
        <p>
          Welcome to SOKE Solutions, your premier destination for cutting-edge technology in Nairobi, Kenya. Located in Bazaar Plaza on Moi Avenue, we are a hub for tech enthusiasts and professionals seeking the latest and greatest in electronics.
</p>
<p>
At SOKE Solutions, we offer an extensive selection of products to cater to all your technological needs. Whether you're in the market for brand-new laptops, Apple products like MacBooks, iPhones, AirPods, iPads, and Mac Minis, or other devices, we've got you covered. We also specialize in desktops, gaming products, and software installations. Our inventory extends to televisions of all brands and high-quality audio devices.
</p>
<p>
We pride ourselves on providing top-notch customer service and after-sales support. Each product we offer comes with a warranty, ensuring peace of mind with every purchase. Our offerings include both brand-new items and high-quality EX-USA and EX-UK devices, providing you with a range of choices that fit various preferences and budgets.
</p>
<p>
At SOKE Solutions, our commitment to excellence goes beyond just selling products. We provide technical assistance and maintenance services to ensure that your experience with our devices remains seamless and satisfying. Whether you're upgrading your current tech setup or diving into the latest innovations, our knowledgeable team is here to help you every step of the way.
</p><p>
Experience the pinnacle of technology at SOKE Solutions—Nairobi's trusted destination for individuals and businesses seeking top-of-the-line laptops and computers. Here, exceptional service and expert knowledge converge to deliver unparalleled quality and reliability in every tech solution we offer.
        </p>
      </div>
    </section>
  );
};

export default About;
