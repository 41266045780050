import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createAddress,
  hideAddressForm,
  updateAddress,
} from "../../../redux/reducers/addressSlice";

const AddressForm = () => {
  const { errMsg, error, addresses, loading, success, addressIndex } =
    useSelector((state) => state.address);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm();
  const { userInfo } = useSelector((state) => state.auth);

  const submitForm = (data) => {
    const { firstname, lastname, phone, email } = data;
    console.log(addresses);
    if (addressIndex === null) {
      dispatch(
        createAddress({
          firstname,
          lastname,
          phone,
          email,
          user: userInfo.user._id,
        })
      );
    } else {
      dispatch(
        updateAddress({
          firstname,
          lastname,
          phone,
          email,
          _id: addresses[addressIndex]._id,
          user: userInfo._id,
        })
      );
    }

    // Reset form fields on success
    if (success) {
      reset({
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
      });
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(submitForm)}
        className="mt-6 w-full flex flex-wrap justify-between px-6 sm:px-12 py-12 border border-grayish-blue relative rounded-md"
      >
        {error && (
          <p className="absolute text-[#f96464] text-sm top-28">{errMsg}</p>
        )}
        <button
          className="absolute top-4 right-4 sm:top-6 sm:right-6 text-dark-blue hover:text-dark-grayish-blue"
          onClick={() => {
            dispatch(hideAddressForm());
            clearErrors();
          }}
        >
          <ion-icon name="close" class="text-lg"></ion-icon>
        </button>

        <div className="relative mt-5 w-full lg:w-[45%]">
          <input
            id="firstname"
            name="firstname"
            type="text"
            className="peer h-10 w-full border-b-2 border-grayish-blue text-very-dark-blue placeholder-transparent focus:outline-none focus:border-orange"
            placeholder="First Name"
            defaultValue={
              addressIndex !== null ? addresses[addressIndex].firstname : ""
            }
            {...register("firstname", {
              required: "Please enter your first name",
            })}
          />
          {errors.firstname && (
            <p className="text-sm text-red-500 italic">
              {errors.firstname.message}
            </p>
          )}
          <label
            htmlFor="firstname"
            className="absolute left-0 -top-3.5 text-dark-grayish-blue text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-grayish-blue peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-dark-grayish-blue peer-focus:text-sm"
          >
            First Name
          </label>
        </div>

        <div className="relative mt-5 w-full lg:w-[45%]">
          <input
            id="lastname"
            name="lastname"
            type="text"
            className="peer h-10 w-full border-b-2 border-grayish-blue text-very-dark-blue placeholder-transparent focus:outline-none focus:border-orange"
            placeholder="Last Name"
            defaultValue={
              addressIndex !== null ? addresses[addressIndex]?.lastname : ""
            }
            {...register("lastname", {
              required: "Please enter your last name",
            })}
          />
          {errors.lastname && (
            <p className="text-sm text-red-500 italic">
              {errors.lastname.message}
            </p>
          )}
          <label
            htmlFor="lastname"
            className="absolute left-0 -top-3.5 text-dark-grayish-blue text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-grayish-blue peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-dark-grayish-blue peer-focus:text-sm"
          >
            Last Name
          </label>
        </div>

        <div className="relative mt-5 w-full">
          <input
            id="phone"
            name="phone"
            type="text"
            className="peer h-10 w-full border-b-2 border-grayish-blue text-very-dark-blue placeholder-transparent focus:outline-none focus:border-orange"
            placeholder="Phone Number"
            defaultValue={
              addressIndex !== null ? addresses[addressIndex].phone : ""
            }
            {...register("phone", {
              required: "Please enter your phone number",
            })}
          />
          {errors.phone && (
            <p className="text-sm text-red-500 italic">
              {errors.phone.message}
            </p>
          )}
          <label
            htmlFor="phone"
            className="absolute left-0 -top-3.5 text-dark-grayish-blue text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-grayish-blue peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-dark-grayish-blue peer-focus:text-sm"
          >
            Phone Number
          </label>
        </div>

        <div className="relative mt-5 w-full">
          <input
            id="email"
            name="email"
            type="email"
            className="peer h-10 w-full border-b-2 border-grayish-blue text-very-dark-blue placeholder-transparent focus:outline-none focus:border-orange"
            placeholder="example@gmail.com"
            defaultValue={
              addressIndex !== null ? addresses[addressIndex].email : ""
            }
            {...register("email", {
              required: "Please enter your email",
            })}
          />
          {errors.email && (
            <p className="text-sm text-red-500 italic">
              {errors.email.message}
            </p>
          )}
          <label
            htmlFor="email"
            className="absolute left-0 -top-3.5 text-dark-grayish-blue text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-grayish-blue peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-dark-grayish-blue peer-focus:text-sm"
          >
            Email
          </label>
        </div>

        <button
          type="submit"
          className={
            "w-full h-14 max-w-lg lg:max-w-none bg-orange rounded-lg lg:rounded-xl mt-10 lg:ml-auto mb-2 text-white flex items-center justify-center lg:w-2/5 shadow-[inset_0_-1px_0_0_#ffede1] hover:shadow-[inset_0_-4rem_0_0_#ffede1] hover:text-orange border transition-all duration-300" +
            (loading ? " cursor-not-allowed" : "")
          }
          disabled={loading}
        >
          {loading ? (
            <div
              className="spinner-border animate-spin inline-block w-4 h-4 border rounded-full"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <>{addressIndex !== null ? <>Update Address</> : <>Submit</>}</>
          )}
        </button>
      </form>
    </>
  );
};

export default AddressForm;
