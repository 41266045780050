import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { server } from "../../data";

export const getAllProducts = createAsyncThunk(
  "product/getAllProducts",
  async (thunkAPI) => {
    let res = await axios.get(`${server}/api/products/`);
    let products = res.data;
    return products;
  }
);

export const getProductsByCategory = createAsyncThunk(
  "product/getProductsByCategory",
  async (category, thunkAPI) => {
    const res = await axios.get(
      `${server}/api/products/getcategory/${category}`
    );
    return res.data;
  }
);
export const getOfferProducts = createAsyncThunk(
  "product/product/offer",
  async (category, thunkAPI) => {
    const res = await axios.get(`${server}/api/products/product/offer`);
    return res.data;
  }
);
export const getSearchedPrice = createAsyncThunk(
  "product/search/query",
  async (searchQuery) => {
    const res = await axios.get(
      `${server}/api/products/product/search/?name=${searchQuery}`
    );
    return res.data;
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
    filteredProducts: [],
    images: [],
    curIndex: 0,
    slideIndex: 0,
    productId: 0,
    product: [],
    loading: true,
    error: false,
    errMsg: "",
    filter: { color: "" },
    containFilters: [],
    sort: "newest",
    colors: [],
    brands: [],
    collection: [],
    searchedProducts: [],
    OfferProducts: [],
    searchErrorMsg: "",
  },
  reducers: {
    getProducts: (state, action) => {
      state.products = action.payload.products;
      state.loading = false;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = true;
      state.errMsg = action.payload.err;
    },
    /*     getFilteredProducts: (state, action) => {
      state.filteredProducts = state.products.filter((item) =>
        item.category.at(-1).category.includes(action.payload.category)
      );
    }, */
    getFilteredProducts: (state, action) => {
      state.filteredProducts = state.products.filter(
        (item) => item.category === action.payload.category
      );
    },
    changeImage: (state, action) => {
      // CHANGE PREVIEW IMG ON CLICK
      state.curIndex = action.payload.index;
    },
    prevPreview: (state, action) => {
      if (state.curIndex < 1) {
        state.curIndex = 0;
      } else {
        state.curIndex -= 1;
      }
    },
    nextPreview: (state, action) => {
      if (state.curIndex > state.images.length - 2) {
        state.curIndex = state.images.length - 1;
      } else {
        state.curIndex += 1;
      }
    },
    prevSlide: (state, action) => {
      if (state.slideIndex < 1) {
        state.slideIndex = 0;
      } else {
        state.slideIndex -= 1;
      }
    },
    nextSlide: (state, action) => {
      if (state.slideIndex > state.images.length - 2) {
        state.slideIndex = state.images.length - 1;
      } else if (
        state.slideIndex > state.images.length - 3 &&
        window.innerWidth > 640
      ) {
        state.slideIndex = state.images.length - 2;
      } else {
        state.slideIndex += 1;
      }
    },
    getProductByName: (state, action) => {
      const searchText = action.payload;
      console.log(searchText);
      const product = state.products.filter((item) =>
        item.name.toLowerCase().includes(searchText)
      );
      state.products = product || [];
      state.error = product ? null : "Product not found";
    },
    getProductItem: (state, action) => {
      state.productId = action.payload.productId;
      state.product = state.products.filter(
        (item) => item._id === state.productId
      )[0];
      state.images = state.error ? null : state.product.images;
    },
    getFilters: (state, action) => {
      // GET LIST OF ALL COLORS FROM PRODUCTS
      console.log("Products:", state.products);
      console.log("Filtered Products:", state.filteredProducts);
      state.colors = Array.from(
        new Set(
          state.colors.concat.apply(
            [],
            (state.filteredProducts.length > 0
              ? state.filteredProducts
              : state.products
            ).map((item) => item.color)
          )
        )
      ).sort();
      // GET LIST OF ALL BRANDS/COMPANIES FROM PRODUCTS
      state.brands = Array.from(
        new Set(
          state.brands.concat.apply(
            [],
            (state.filteredProducts.length > 0
              ? state.filteredProducts
              : state.products
            ).map((item) => item.category)
          )
        )
      ).sort();
    },
    selectFilters: (state, action) => {
      state.filter = action.payload.filter;

      // return an array of true and false based on if the product contains a filter
      if (state.filter.color === "" && state.filter.category === "") {
        state.containFilters = (
          state.filteredProducts.length < 1
            ? state.products
            : state.filteredProducts
        ).map((item) => true);
      } else if (state.filter.category !== "" && state.filter.color === "") {
        state.containFilters = (
          state.filteredProducts.length < 1
            ? state.products
            : state.filteredProducts
        ).map((item) =>
          item.category ? item.category.includes(state.filter.category) : false
        );
      } else {
        state.containFilters = (
          state.filteredProducts.length < 1
            ? state.products
            : state.filteredProducts
        ).map((item) =>
          item.category && item.category.length > 0
            ? item.category[item.category.length - 1][state.filter.color] ===
              state.filter.color
            : false
        );
      }
    },
    selectSort: (state, action) => {
      state.sort = action.payload.sort;
      let items =
        state.filteredProducts.length < 1
          ? state.products
          : state.filteredProducts;

      switch (action.payload.sort) {
        case "newest":
          items = (
            state.filteredProducts.length < 1
              ? state.products
              : state.filteredProducts
          ).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
          break;
        case "asc":
          items = (
            state.filteredProducts.length < 1
              ? state.products
              : state.filteredProducts
          ).sort((a, b) => a.price - b.price);
          break;
        case "desc":
          items = (
            state.filteredProducts.length < 1
              ? state.products
              : state.filteredProducts
          ).sort((a, b) => b.price - a.price);
          break;
        default:
          // eslint-disable-next-line
          items = (
            state.filteredProducts.length < 1
              ? state.products
              : state.filteredProducts
          ).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
          break;
      }
    },
  },
  extraReducers: {
    [getAllProducts.pending]: (state) => {
      state.loading = true;
    },
    [getAllProducts.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.products = payload;
      state.containFilters = state.products.map((item) => true);
    },
    [getAllProducts.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.errMsg = action.error.message;
    },
    [getProductsByCategory.pending]: (state) => {
      state.loading = true;
    },
    [getProductsByCategory.fulfilled]: (state, { payload, meta }) => {
      state.loading = false;
      state.products = payload;
    },
    [getProductsByCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.errMsg = action.error.message;
    },
    [getOfferProducts.pending]: (state) => {
      state.loading = true;
    },
    [getOfferProducts.fulfilled]: (state, { payload, meta }) => {
      state.loading = false;
      state.OfferProducts = payload;
    },
    [getOfferProducts.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.errMsg = action.error.message;
    },
    [getSearchedPrice.pending]: (state) => {
      state.loading = true;
    },
    [getSearchedPrice.fulfilled]: (state, { payload, meta }) => {
      state.loading = false;
      state.searchedProducts = payload;
    },
    [getSearchedPrice.rejected]: (state, action) => {
      state.loading = false;
      state.error = true;
      state.searchErrorMsg = action.error.msg;
    },
  },
});

export const {
  getProducts,
  setError,
  getFilteredProducts,
  changeImage,
  prevPreview,
  nextPreview,
  prevSlide,
  nextSlide,
  getProductItem,
  quantityCount,
  selectFilters,
  getProductByName,
  selectSort,
  getFilters,
} = productSlice.actions;
export default productSlice.reducer;
