import React, { useEffect } from "react";
import Hero from "../../components/home/Hero";
import Benefits from "../../components/home/Benefits";
import HomeProductSection from "../../components/home/HomeProductsSection";
import { getAllProducts } from "../../redux/reducers/productSlice";
import { useDispatch } from "react-redux";

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProducts());
  }, []);
  return (
    <>
      <Hero />
      <HomeProductSection />
      <Benefits />
    </>
  );
};

export default Home;
