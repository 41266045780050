import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSearchedPrice } from "../../redux/reducers/productSlice";
import { useDispatch, useSelector } from "react-redux";
import ProductItem from "../../components/home/ProductItem";
import { toast } from "react-toastify";

const SearchResultsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //getting searched products
  const searchResults = useSelector((state) => state.product.searchedProducts);
  const searchErrorMsg = useSelector((state) => state.product.searchErrorMsg);

  //search params
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.get("q") || "");
  const handleSearch = async () => {
    if (!searchQuery)
      return toast.error(
        "Please Enter a valid Product name or Description  to search"
      );
    setSearchParams({ q: searchQuery });
    await dispatch(getSearchedPrice(searchQuery));
    navigate(`/search?q=${encodeURIComponent(searchQuery)}`);
  };
  if (searchErrorMsg) toast.error(searchErrorMsg);
  return (
    <>
      <div className="flex  relative w-3/4 md:w-full mx-auto gap-2 items-center  z-[2] justify-center ">
        <input
          type="search"
          name="searchText"
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
          placeholder="Search..."
          onKeyDown={(e) => e.key === "Enter" && handleSearch()}
          className="border border-gray-300 md:w-3/4  mb-5 rounded-md px-4 py-2 mt-2 lg:mt-0"
        />
        <div
          onClick={handleSearch}
          className="relative bg-orange mb-5 mt-2 cursor-pointer rounded-md active:scale-110 transition duration-500 px-4 py-2 z-[2] inset-y-0  left-0 pl-3 flex items-center"
        >
          <AiOutlineSearch className="text-gray-400 text-2xl text-white rounded-md active:scale-110 transition duration-500" />
        </div>{" "}
      </div>
      <div className="product-container max-w-2xl mx-auto lg:max-w-7xl px-4 lg:px-0 my-10 lg:my-32">
        <div className="grid grid-cols-2 gap-y-12 sm:y-10 gap-x-6 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-8">
          {searchResults.map((product, index) => (
            <ProductItem key={product._id} product={product} />
          ))}
        </div>
      </div>
    </>
  );
};

export default SearchResultsPage;
