import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "../pages/Home/Home";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Login from "../pages/User/Login";
import Register from "../pages/User/Register";
import Layout from "../components/layout/Layout";
import Products from "../pages/Products";
import ProductPage from "../pages/ProductPage";
import NotFound from "../pages/NotFound";
import Checkout from "../pages/Checkout";
import { useSelector } from "react-redux";
import UserProfile from "../pages/User/UserProfile";
import Settings from "../pages/User/Profile/Settings";
import MyAccount from "../pages/User/Profile/MyAccount";
import MyOrders from "../pages/User/Profile/MyOrders";
import MyAddress from "../pages/User/Profile/MyAddress";
import Notifications from "../pages/User/Profile/Notifications";
import Password from "../pages/User/Profile/Password";
import PaymentSuccessful from "../components/PaymentSuccessful";
import PaymentOnDeliverySucess from "../components/paymentOnDeliverySucess";
import Categoriedproducts from "../pages/Categoriedproducts/Categoriedproducts";
import SearchResultsPage from "../pages/searchpage/SearchResultsPage";
import Returnpolicy from "../pages/Returnpolicy";

const MyRoutes = () => {
  const user = false;
  const { cartItems, userCartItems } = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.auth);

  return (
    <>
      <Layout>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/returnpolicy" element={<Returnpolicy />} />
          <Route path="/products/" element={<Products />} />
          <Route path="/products/:id" element={<ProductPage />} />
          <Route path="/productss/:category" element={<Categoriedproducts />} />
          {/*           <Route path="/contact" element={<Contact />} />
           */}
          <Route
            path="/login"
            element={user ? <Navigate to="/" replace={true} /> : <Login />}
          />
          <Route
            path="/register"
            element={user ? <Navigate to="/" replace={true} /> : <Register />}
          />
          <Route path="/user-profile" element={<UserProfile />}>
            <Route path="" element={<MyAccount />} />
            <Route path="orders" element={<MyOrders />} />
            <Route path="addresses" element={<MyAddress />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="password" element={<Password />} />
            <Route path="settings" element={<Settings />} />
          </Route>
          <Route path="/search" element={<SearchResultsPage />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/payment" element={<PaymentSuccessful />} />
          <Route
            path="/payment-on-delivery"
            element={<PaymentOnDeliverySucess />}
          />
        </Routes>
      </Layout>
    </>
  );
};

export default MyRoutes;
