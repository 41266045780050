import React, { useEffect, useState } from "react";
import aboutHeader from "../../assets/page-header/about-header.png";
import ProductItem from "../../components/home/ProductItem";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { toast } from "react-toastify";

import {
  getFilteredProducts,
  getProductByName,
  getProductsByCategory,
  getSearchedPrice,
  selectFilters,
} from "../../redux/reducers/productSlice";

const Categoriedproducts = () => {
  document.title = "All Products";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const products = useSelector((state) => state.product.products);
  const loading = useSelector((state) => state.product.loading);
  const error = useSelector((state) => state.product.error);
  const errMsg = useSelector((state) => state.product.errMsg);
  const containFilters = useSelector((state) => state.product.containFilters);
  const filter = useSelector((state) => state.product.filter);
  //search params
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.get("q") || "");
  const { category } = useParams(); // Get category from URL params

  // Your existing useSelector statements

  useEffect(() => {
    // Dispatch getProductsByCategory(category) when the component mounts or category changes
    dispatch(getProductsByCategory(category));

    return () => {
      // Clean up function to reset the filters when the component unmounts
      dispatch(
        selectFilters({ filter: { ...filter, color: "", category: "" } })
      );
    };
  }, [dispatch, category]); // Include category in dependency array
  const handleSearch = async () => {
    if (!searchQuery)
      return toast.error(
        "Please Enter a valid Product name or Description  to search"
      );
    setSearchParams({ q: searchQuery });
    await dispatch(getSearchedPrice(searchQuery));
    navigate(`/search?q=${encodeURIComponent(searchQuery)}`);
  };
  return (
    <section className="h-auto pt-2 min-h-[80vh]">
      <div className="max-w-4xl sm:max-w-5xl lg:max-w-7xl relative px-2 pt-20 pb-12 items-center mx-auto lg:mx-20 xl:mx-28 2xl:mx-40 3xl:mx-auto lg:pb-2 lg:px-1 xl:px-3 2xl:px-1">
        <h2 className="product capitalize text-white font-bold text-center relative z-[1] lg:text-left text-3xl sm:text-4xl sm:leading-none pb-3 px-8">
          Products/{category}
        </h2>
        <div className="flex  relative w-3/4 md:w-full mx-auto gap-2 items-center  z-[2] justify-center ">
          <input
            type="search"
            name="searchText"
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            placeholder="Search..."
            onKeyDown={(e) => e.key === "Enter" && handleSearch()}
            className="border border-gray-300 md:w-3/4  mb-5 rounded-md px-4 py-2 mt-2 lg:mt-0"
          />
          <div
            onClick={handleSearch}
            className="relative bg-orange mb-5 mt-2 cursor-pointer rounded-md active:scale-110 transition duration-500 px-4 py-2 z-[2] inset-y-0  left-0 pl-3 flex items-center"
          >
            <AiOutlineSearch className="text-gray-400 text-2xl text-white rounded-md active:scale-110 transition duration-500" />
          </div>{" "}
        </div>
        <div className="absolute top-0 left-0 bg-dark-grayish-blue w-full h-48 rounded-md overflow-hidden">
          <img
            src={aboutHeader}
            alt="rows of sneakers"
            className="opacity-10 absolute h-full w-full object-cover"
          />
        </div>
        {!error ? (
          <>
            {loading ? (
              <Loading />
            ) : (
              <>
                {products.length === 0 ? (
                  <div className="text-center flex flex-col mt-20">
                    <p className="text-very-dark-blue mb-6 text-2xl">
                      Products are out of stock.
                    </p>
                    <Link to="/products">
                      <button className="w-full max-w-xs m-auto bg-orange border border-orange rounded-md py-3 px-4 text-base font-medium text-white shadow-[inset_0_0_0_0_#ffede0] hover:shadow-[inset_0_-4rem_0_0_#ffede1] hover:text-orange transition-all duration-300">
                        View Other Products
                      </button>
                    </Link>
                  </div>
                ) : (
                  <div className="product-container max-w-2xl mx-auto lg:max-w-7xl px-4 lg:px-0 my-20 lg:my-32">
                    <div className="grid grid-cols-2 gap-y-12 sm:y-10 gap-x-6 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-8">
                      {products.map((product, index) => (
                        <ProductItem
                          key={product._id}
                          product={product}
                          containFilter={containFilters[index]}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <p className="mt-20 text-center text-very-dark-blue">
            {errMsg}. Reload page
          </p>
        )}
      </div>
    </section>
  );
};

export default Categoriedproducts;
