// In your categorySlice.js file

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { server } from "../../data";

// Define the initial state
const initialState = {
  loading: false,
  error: null,
  categories: [
    "iPads",
    "iPhones",
    "Mac",
    "Accessories",
    "Phones & Devices",
    "Audio",
    "TVs",
    "Gaming",
  ],
};

// Create an async thunk to fetch all products
export const getAllProducts = createAsyncThunk(
  "product/getAllProducts",
  async () => {
    const res = await axios.get(`${server}/api/products/`);
    return res.data;
  }
);

// Create a slice for product state
const categorySlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    // Define your other reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.fulfilled, (state, action) => {
        // Update categories based on fetched products
        const uniqueCategories = new Set(state.categories);

        action.payload.forEach((product) => {
          const trimmedCategory = product.category.trim();
          uniqueCategories.add(trimmedCategory);
        });

        state.categories = Array.from(uniqueCategories);
      })
      .addCase(getAllProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default categorySlice.reducer;
