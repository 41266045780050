import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import heroImg from "../../assets/hero-iphone.png";
import heroImg2 from "../../assets/hero-imac.png";
import heroImg3 from "../../assets/hero-mac.png";
import heroImg4 from "../../assets/hero-headphones.png";
import heroImg5 from "../../assets/hero-tv.png";
import { motion, AnimatePresence } from "framer-motion";
import {
  getOfferProducts,
  getSearchedPrice,
} from "../../redux/reducers/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/reducers/cartSlice";
import { AiOutlineSearch } from "react-icons/ai";
import { toast } from "react-toastify";

const Hero = () => {
  document.title = "Soke Solutions";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const OfferProducts = useSelector((state) => state.product.OfferProducts);
  const [productIndex, setCurrentProductIndex] = useState(0);
  //search params
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.get("q") || "");
  useEffect(() => {
    dispatch(getOfferProducts());
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentProductIndex(
        (prevIndex) => (prevIndex + 1) % OfferProducts.length
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [OfferProducts.length]);
  const product = OfferProducts[productIndex];

  const handleClick = () => {
    dispatch(addToCart({ product, quantity: 1 }));
    navigate("/checkout");
  };
  const handleSearch = async () => {
    if (!searchQuery)
      return toast.error(
        "Please Enter a valid Product name or Description  to search"
      );
    setSearchParams({ q: searchQuery });
    await dispatch(getSearchedPrice(searchQuery));
    navigate(`/search?q=${encodeURIComponent(searchQuery)}`);
  };
  return (
    <AnimatePresence>
      <div className="flex  md:my-5   w-3/4 md:w-full mx-auto gap-2 items-center  z-[2] justify-center ">
        <input
          type="search"
          name="searchText"
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
          placeholder="Search..."
          onKeyDown={(e) => e.key === "Enter" && handleSearch()}
          className="border border-gray-300 md:w-3/4  mb-5 rounded-md px-4 py-2 mt-2 lg:mt-0"
        />
        <div
          onClick={handleSearch}
          className="relative bg-orange mb-5 mt-2 cursor-pointer rounded-md active:scale-110 transition duration-500 px-4 py-2 z-[2] inset-y-0  left-0 pl-3 flex items-center"
        >
          <AiOutlineSearch className="text-gray-400 text-2xl text-white rounded-md active:scale-110 transition duration-500" />
        </div>{" "}
      </div>
      <section className="px-5 py-4 lg:py-24 mt-10 flex flex-col lg:flex-row items-center justify-between lg:mx-20 xl:mx-28 2xl:mx-36 3xl:mx-auto lg:px-0 xl:px-3 max-w-xl md:max-w-xl mx-auto lg:max-w-7xl">
        <motion.div
          initial={{ opacity: 0, x: "-100%" }}
          animate={{ opacity: 1, x: 0 }}
          id="hero-details"
          className="container order-2 lg:order-1 text-center lg:text-left mx-auto pt-5 sm:pt-10 lg:pt-5 pb-20 lg:pb-5 lg:px-0 xl:mr-1 w-full lg:w-1/2 relative z-[1]"
        >
          <h1 className="capitalize text-very-dark-blue font-bold text-3xl sm:text-4xl lg:text-5xl xl:text-6xl sm:leading-none pb-1 sm:pb-5">
            Buy your dream Phone and Laptop
          </h1>
          <p className="text-dark-grayish-blue lg:leading-6 py-6 lg:py-7">
            With a wide range of quality and affordable devices to choose from,
            browse through our collections for that Iphone you’ve always wanted.
          </p>
          <Link to="products">
            <button className="w-full max-w-xs m-auto bg-orange border border-orange rounded-md py-3 px-4 text-base font-medium text-white shadow-[inset_0_0_0_0_#ffede0] hover:shadow-[inset_0_-4rem_0_0_#ffede1] hover:text-orange transition-all duration-300">
              Explore Products
            </button>
          </Link>
        </motion.div>
        <motion.figure
          initial={{ opacity: 0, x: "100%" }}
          animate={{ opacity: 1, x: 0 }}
          className="hero-img order-1 lg:order-2 w-full lg:w-1/2 lg:ml-4 relative"
        >
          {product && (
            <div className="flex flex-col">
              <div className="w-96 h-96">
                <img
                  src={`https://nadtech.sokeserver.nadtech.co.ke/Images/${product?.image}`}
                  alt={product?.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <h2 className="text-md  font-semibold mb-2">{product?.name}</h2>
              <p className="text-gray-700  mb-1">Ksh.{product?.price}</p>
              <p className="original-price text-grayish-blue line-through">
                Ksh.{product.initialprice}
              </p>
              <p className="uppercase   text-red-500 font-extrabold  ">
                upto{" "}
                {Math.floor(
                  ((product?.initialprice - product?.price) /
                    product?.initialprice) *
                    100
                )}
                % off
              </p>

              <button
                onClick={handleClick}
                className="  text-[10px] w-24 h-7 sm:h-10 bg-orange rounded-lg lg:rounded- sm:mb-2 shadow-orange-shadow shadow-md text-white flex items-center justify-center  hover:opacity-60"
              >
                Buy Now
              </button>
            </div>
          )}
          <div className="absolute w-full h-full bottom-16 sm:bottom-24 lg:bottom-24 -z-10 left-28 sm:left-44 lg:left-48">
            <div className=" h-inherit">
              <svg
                className="w-full scale-[1.4] sm:scale-[1.2] lg:scale-[1.5]  -z-10"
                viewBox="0 0 200 200"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#FFEDE0"
                  d="M40.7,-68.2C48.9,-65.7,49.1,-47.1,54.7,-33.1C60.4,-19,71.3,-9.5,71,-0.2C70.6,9.1,58.8,18.1,50.1,26.8C41.4,35.5,35.8,43.8,27.9,54.9C20.1,66,10,79.9,0.5,79.1C-9.1,78.3,-18.2,62.8,-27.6,52.6C-37,42.4,-46.7,37.5,-57.4,29.6C-68.1,21.7,-79.8,10.9,-83.4,-2.1C-87.1,-15.1,-82.8,-30.2,-71.9,-37.7C-61,-45.3,-43.6,-45.3,-30.5,-45C-17.4,-44.7,-8.7,-44,3.8,-50.5C16.2,-57.1,32.5,-70.7,40.7,-68.2Z"
                  transform="translate(100 100)"
                />
              </svg>
            </div>
          </div>
        </motion.figure>
      </section>
    </AnimatePresence>
  );
};

export default Hero;
