import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../Loading";
import ProductItem from "./ProductItem";

const HomeProductsSection = () => {
  const { products, containFilters, errMsg, error, loading } = useSelector(
    (state) => state.product
  );
  const [showMore, setShowMore] = useState({});

  // Define custom sorting order for categories
  const categoryOrder = {
    Laptops: 1,
    Mac: 2,
    IPhones: 3,
    Desktops: 4,
    iPads: 5,
    "Phones and devices": 6,
    Audio: 7,
    Accessories: 8,
    Gaming: 9,
    TVs: 10,
  };

  // Group products by category
  const groupedProducts = products.reduce((acc, product) => {
    acc[product.category] = acc[product.category] || [];
    acc[product.category].push(product);
    return acc;
  }, {});

  console.log("Grouped Products:", groupedProducts); // Debugging: Check grouped products

  // Toggle show more products
  const toggleShowMore = (category) => {
    setShowMore((prev) => ({
      ...prev,
      [category]: prev[category] ? null : 5, // Toggle between showing 5 products or none
    }));
  };

  // Sort categories based on the custom order
  const sortedCategories = Object.keys(groupedProducts).sort((a, b) => {
    // Use categoryOrder for sorting, default to Infinity if not found
    return (categoryOrder[a] ) - (categoryOrder[b] );
  });

  console.log("Sorted Categories:", sortedCategories); // Debugging: Check sorted categories

  // Render products for each category
  const renderProducts = () => {
    return sortedCategories.map((category) => {
      const productsInCategory = showMore[category]
        ? groupedProducts[category]
        : groupedProducts[category].slice(0, 5);

      return (
        <div key={category}>
          <Link
            to={`/productss/${category}`}
            className="company flex items-center justify-center uppercase text-orange font-bold text-[1rem] tracking-wider pb-1 pt-2 text-center"
          >
            <button className="cart min-w-[4rem] w-full max-w-xs md:max-w-lg lg:max-w-none h-14 bg-pale-orange border border-orange rounded-lg mt-12 mx-auto text-orange font-bold flex items-center justify-center lg:w-52 shadow-[inset_0_0_0_0_#ff7d1a] hover:shadow-[inset_0_-4rem_0_0_#ff7d1a] hover:text-white transition-all duration-300">
              {category}
            </button>
          </Link>
          <div className="product-container max-w-2xl mx-auto lg:max-w-7xl px-2 lg:px-0 my-3 lg:my-32">
            <div className="grid grid-cols-2 gap-y-6 sm:y-10 gap-x-6 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-8">
              {productsInCategory.map((product) => (
                <ProductItem
                  key={product._id}
                  product={product}
                  containFilter={containFilters.find(filter => filter._id === product._id)} // Match the correct filter
                />
              ))}
            </div>
          </div>
          {groupedProducts[category].length > 5 && (
            <div className="mx-8 mt-5 sm:mt-5 lg:mt-5">
              <button
                onClick={() => toggleShowMore(category)}
                className="cart min-w-[4rem] w-full max-w-xs md:max-w-lg lg:max-w-none h-14 bg-pale-orange border border-orange rounded-lg mt-12 mx-auto text-orange font-bold flex items-center justify-center lg:w-52 shadow-[inset_0_0_0_0_#ff7d1a] hover:shadow-[inset_0_-4rem_0_0_#ff7d1a] hover:text-white transition-all duration-300"
              >
                {showMore[category] ? "Show Less" : "Show More"}
              </button>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <section className="bg-light-grayish-blue py-12">
      <div className="lg:px-0 xl:px-3 lg:mx-20 xl:mx-28 2xl:mx-36 3xl:mx-auto max-w-3xl mx-auto lg:max-w-7xl lg:pb-16 lg:mt-7">
        <h2 className="text-very-dark-blue font-bold text-2xl text-center mx-auto lg:text-left sm:text-4xl sm:leading-none py-6 sm:pb-5 mb-6 lg:mb-9">
          Products
        </h2>
        <div className="max-w-2xl mx-auto lg:max-w-7xl px-4 lg:px-0">
          {!error ? (
            loading ? (
              <div className="my-20 mx-auto">
                <Loading />
              </div>
            ) : (
              <div>{renderProducts()}</div>
            )
          ) : (
            <p className="mt-20 text-center text-very-dark-blue">
              {errMsg}. Reload page
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default HomeProductsSection;
