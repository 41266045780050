import React from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { addToCart } from "../../redux/reducers/cartSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";

const ProductItem = ({ product, containFilter }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    dispatch(addToCart({ product, quantity: 1 }));
    navigate("/checkout");
  };
  const handleWhatsAppClick = () => {
    if (!product) return; // Product details not available yet

    const message = `Product: ${product.name}\nPrice: Ksh.${product.price}\nQuantity:1`;
    const whatsappLink = `https://api.whatsapp.com/send?phone=254769758399&text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappLink, "_blank");
  };
  return (
    <AnimatePresence>
      <motion.div
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={
          "group relative h-fit border-1 border-solid border-orange rounded-md" +
          (containFilter ? "block" : "hidden")
        }
      >
        <div className="w-full  min-h-40 bg-grayish-blue aspect-w-1 aspect-h-1 rounded-md overflow-hidden h-20 sm:h-40 lg:aspect-none relative before:absolute before:bg-[hsla(222,_4%,_48%,_0.4)] before:inset-0 text-center before:h-0 group-hover:before:h-full before:transition-all ">
          <img
            src={`https://nadtech.sokeserver.nadtech.co.ke/Images/${product.images[0]}`}
            alt={product.slug}
            className=" object-cover object-center w-full h-full flex items-center justify-center"
          />
        </div>
        <div className="p-2">
          <Link
            to={`/products/${product._id}`}
            className="cursor-pointer ring-2 "
          >
            {/*             <span aria-hidden="true" className="absolute inset-0" />
             */}{" "}
            <p className="company uppercase text-orange font-bold text-[10px] trackin-widest tracking-wider pb-1 pt-2">
              {product.category}
            </p>
            <h3 className="product mb-4 md:mb-1 h-3 sm:h-4 capitalize text-very-dark-blue text-[12px] sm:text-[12px] font-bold sm:leading-none pb-2 ">
              {product.name.split(" ").slice(0, 5).join(" ")}
              {product.name.split(" ").length > 5 ? " ..." : ""}
            </h3>
          </Link>
          <div className="flex justify-between items-center text-[10px]">
            <div className="discount-price font-bold">
              Ksh.{product.discountPrice}
            </div>
            <div className="original-price text-grayish-blue line-through">
              Ksh{product.price}
            </div>
            <div className="discount flex items-center text-orange bg-pale-orange font-bold w-max px-2 sm:rounded mx-2 h-3 sm:h-6">
              {Math.floor(
                ((product.price - product.discountPrice) / product.price) * 100
              )}
              %
            </div>
          </div>
        </div>
        <div className="flex gap-1 sm:gap-3 p-2 sm:mt-1">
          <button
            onClick={() => dispatch(addToCart({ product, quantity: 1 }))}
            className="cart text-[10px] sm:text-base w-full h-7 sm:h-10 bg-orange rounded-lg lg:rounded-xl  shadow-orange-shadow shadow-md text-white flex items-center justify-center lg:w-3/5 hover:opacity-60"
          >
            <i className="cursor-pointer text-white text-[10px] sm:text-xl leading-0 pr-3">
              <ion-icon name="cart-outline"></ion-icon>
            </i>
          </button>
          <button
            onClick={handleClick}
            className="cart  text-[10px] w-full h-7 sm:h-10 bg-orange rounded-lg lg:rounded- sm:mb-2 shadow-orange-shadow shadow-md text-white flex items-center justify-center lg:w-3/5 hover:opacity-60"
          >
            Buy Now
          </button>
        </div>
        <button
          onClick={handleWhatsAppClick}
          className="whatsapp mx-9 mx-auto text-[12px] text-nowrap w-full h-fit bg-orange  rounded-sm p-2 lg:rounded-xl mb-2 shadow-green-shadow shadow-2xl  gap-3 text-white flex  items-center justify-center lg:w-3/5 hover:opacity-60"
        >
          <FaWhatsapp className="text-white" />
          Buy On WhatsApp
        </button>
      </motion.div>
    </AnimatePresence>
  );
};

export default ProductItem;
