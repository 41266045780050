import React from 'react';
import AboutHeader from "../assets/page-header/about-header.png";

const ReturnPolicy = () => {
  return (
    <section className="relative h-auto pt-2">
      <div className="max-w-xl sm:max-w-4xl lg:max-w-7xl px-5 pt-20 pb-12 mx-auto lg:mx-20 xl:mx-28 2xl:mx-40">
        <h2 className="text-white font-bold text-center lg:text-left text-3xl sm:text-4xl sm:leading-none pb-3 px-8 relative z-10 capitalize">
          Return Policy
        </h2>
        <div className="absolute top-0 left-0 bg-dark-grayish-blue w-full h-48 rounded-md overflow-hidden">
          <img
            src={AboutHeader}
            alt="Background image"
            className="opacity-10 absolute h-full w-full object-cover"
          />
        </div>
      </div>
      <div className="mx-auto mb-6 w-3/4 mt-16 px-4 flex flex-col gap-6">
        <h1 className="text-2xl font-bold">SOKE Solutions Return Policy</h1>

        <div className="space-y-4">
          <div>
            <h2 className="text-xl font-semibold">1. Eligibility for Returns</h2>
            <p>
              At SOKE Solutions, we strive to ensure you are completely satisfied with your purchase. If you are not satisfied, we offer a return policy for eligible items. To qualify for a return, the item must be:
            </p>
            <ul className="list-disc list-inside pl-5">
              <li>Purchased directly from SOKE Solutions.</li>
              <li>Returned within 2 days of the purchase date.</li>
              <li>In its original, unused, and resalable condition, with all original packaging and tags intact.</li>
            </ul>
          </div>

          <div>
            <h2 className="text-xl font-semibold">2. Non-Returnable Items</h2>
            <p>The following items are non-returnable:</p>
            <ul className="list-disc list-inside pl-5">
              <li>Customized or personalized products.</li>
              <li>Opened or used items, unless defective.</li>
              <li>Digital products or software downloads.</li>
            </ul>
          </div>

          <div>
            <h2 className="text-xl font-semibold">3. Return Process</h2>
            <p>To initiate a return, please follow these steps:</p>
            <ol className="list-decimal list-inside pl-5">
              <li>Contact our customer service team at <a href="mailto:sokesolutionsltd@gmail.com" className="text-blue-600">sokesolutionsltd@gmail.com</a> or call us at <a href="tel:+254769758399" className="text-blue-600">+254769758399</a> to request a return authorization. Provide your order number and a brief description of the reason for the return.</li>
              <li>Upon approval, you will receive a return authorization number and detailed instructions for returning the item.</li>
              <li>Pack the item securely in its original packaging. Clearly write the return authorization number on the package.</li>
              <li>Ship the package to the address provided by our customer service team.</li>
            </ol>
          </div>

          <div>
            <h2 className="text-xl font-semibold">4. Return Shipping Costs</h2>
            <p>
              If the return is due to a defect or an error on our part, we will cover the return shipping costs.
              For all other returns, you are responsible for the return shipping costs.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold">5. Refunds and Exchanges</h2>
            <p>
              Once we receive and inspect the returned item, we will process your refund to the original payment method. Please allow 5-7 business days for the refund to appear in your account.
              If you prefer an exchange, please indicate this when contacting customer service. We will arrange for the exchange once the original item is received.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold">6. Damaged or Defective Items</h2>
            <p>
              If you receive an item that is damaged or defective, please contact us immediately at <a href="mailto:sokesolutionsltd@gmail.com" className="text-blue-600">sokesolutionsltd@gmail.com</a> or call us at <a href="tel:+254769758399" className="text-blue-600">+254769758399</a>. We will provide instructions for returning the item and offer a replacement or a full refund.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-semibold">7. Contact Us</h2>
            <p>
              For any questions regarding our return policy or assistance with a return, please contact our customer service team at <a href="mailto:sokesolutionsltd@gmail.com" className="text-blue-600">sokesolutionsltd@gmail.com</a> or call us at <a href="tel:+254769758399" className="text-blue-600">+254769758399</a>.
            </p>
          </div>
        </div>

        <p className="text-center mt-8 text-gray-600">Thank you for choosing SOKE Solutions!</p>
      </div>
    </section>
  );
}

export default ReturnPolicy;
