import { BrowserRouter } from "react-router-dom";
import MyRoutes from "./routes/MyRoutes";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setUser } from "./redux/reducers/authSlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("Profile"));
  useEffect(() => {
    dispatch(setUser(user));
  }, []);
  const paymentID = localStorage.getItem("paymentID");
  return (
    <BrowserRouter>
      <div className="App  w-full min-h-screen relative overflow-hidden">
        <MyRoutes />
      </div>
      <ToastContainer
        position="bottom-center" // Set position to bottom center
      />
    </BrowserRouter>
  );
}

export default App;
